/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Avatar from '@material-ui/core/Avatar';
import georg from '@signatu/media/images/georg.png';
import harsh from '@signatu/media/images/harsh.png';
import torgeir from '@signatu/media/images/torgeir.png';
import olePetter from '@signatu/media/images/ole-petter.png';
import henriette from '@signatu/media/images/henriette.png';
export const AVATAR_SIZE = 120;
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "georg-philip-krog",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#georg-philip-krog",
    "aria-label": "georg philip krog permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Georg Philip Krog"), "\n", React.createElement(Avatar, {
    alt: "Georg Philip Krog",
    src: georg,
    style: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Co-founder & Chief Legal Counsel")), "\n", React.createElement(_components.p, null, "Georg is the driving force with the vision for Signatu to act as a key enabler of the data economy through standardization and interoperability of legal information\nto facilitate trusted and compliant data sharing across sectors. Georg holds a Cand. Jur. (MSc Law) from the University of Oslo, and has had research positions at UiO, Max Planck Institut,\nStanford Law School, Harvard Law School and WIPO."), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:georg@signatu.com"
  }, "georg@signatu.com")), "\n", React.createElement(_components.h2, {
    id: "dr-harshvardhan-pandit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dr-harshvardhan-pandit",
    "aria-label": "dr harshvardhan pandit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dr. Harshvardhan Pandit"), "\n", React.createElement(Avatar, {
    alt: "Harshvardhan Pandit",
    src: harsh,
    style: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "World leading expert in modelling information for GDPR, consent, EU regulations")), "\n", React.createElement(_components.p, null, "Dr. Pandit is an Assistant Professor at the School of Computing in Dublin City University.\nHis PhD (Computer Science, Trinity College Dublin) explored use of open linked data for GDPR compliance.\nHe is the chair of W3C Data Privacy Vocabularies and Controls Community Group (DPVCG) and develops\nEU and ISO standards in the areas of information security, privacy, and AI."), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:me@harshp.com"
  }, "me@harshp.com")), "\n", React.createElement(_components.h2, {
    id: "henriette-dedichen",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#henriette-dedichen",
    "aria-label": "henriette dedichen permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Henriette Dedichen"), "\n", React.createElement(Avatar, {
    alt: "Henriette Dedichen",
    src: henriette,
    style: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Head of Partnerships and Events")), "\n", React.createElement(_components.p, null, "Henriette holds degrees in art history, history, European political history and social anthropology from the University of Oslo, and has worked at the Munch Museum and Harvard University, before establishing her own art business facilitating successful collaborative relations and projects between private and public art institutions, art collectors and artists."), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:henriette@signatu.com"
  }, "henriette@signatu.com")), "\n", React.createElement(_components.h2, {
    id: "ole-petter-tennfjord",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ole-petter-tennfjord",
    "aria-label": "ole petter tennfjord permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ole-Petter Tennfjord"), "\n", React.createElement(Avatar, {
    alt: "Ole-Petter Tennfjord",
    src: olePetter,
    style: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "CFO and Chairman of the Board")), "\n", React.createElement(_components.p, null, "Ole-Petter holds an MSc in Business Finance from Norwegian School of Economics (NHH)."), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:opt@dark.no"
  }, "opt@dark.no")), "\n", React.createElement(_components.h2, {
    id: "torgeir-hovden",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#torgeir-hovden",
    "aria-label": "torgeir hovden permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Torgeir Hovden"), "\n", React.createElement(Avatar, {
    alt: "Torgeir Hovden",
    src: torgeir,
    style: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Co-founder")), "\n", React.createElement(_components.p, null, "Torgeir has extensive experience building software products, including search engines, video streaming applications and identity solutions.\nPreviously Torgeir served as CTO Telenor Digital, Principal Engineer in Microsoft and Sr Director, Fast Search & Transfer."), "\n", React.createElement(_components.p, null, "Torgeir holds a Siv. Ing. (MSc) in Computer Science from ", React.createElement(_components.a, {
    href: "https://www.ntnu.edu/"
  }, "NTNU"), ", and a Master of Technlogy Management (MBA)\nfrom NTNU, ", React.createElement(_components.a, {
    href: "https://sloan.mit.edu"
  }, "MIT Sloan"), " and ", React.createElement(_components.a, {
    href: "https://www.nhh.no/en/"
  }, "NHH"), "."), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:torgeir@signatu.com"
  }, "torgeir@signatu.com")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
